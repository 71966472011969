import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import AboutUs from "@/components/about-us";
import HeaderInner from "@/components/header-inner";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import SliderAbout from "@/components/slider-about";
import Support from "@/components/support";

const AboutPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Vitalis Olimpíada de Medicina - Sobre Nós">
          <HeaderInner />
          <SliderAbout />
          <AboutUs />
          <Support />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default AboutPage;
