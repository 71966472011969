/** @jsx jsx */
import React from "react";
import { AboutUsData, Corners } from "@/data";
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from "@emotion/react";

import {
  abAgency,
  pr79,
  aboutGroup,
  abImg,
  molecula,
} from "../assets/styles/AboutUs.styles";
import {
  secTitle,
  subtitle,
  secDesk,
  commonSection,
  commonBtn,
  redBg,
} from "../assets/styles/layout.styles";

const { primaryCBR, secondaryCBR, secondaryCTR, secondaryCTL } = Corners;

const AboutUs = () => {
  const { sectionContent, button, gallery } = AboutUsData;
  return (
    <section css={[commonSection, abAgency]}>
        <img src={secondaryCTL} className="ctl" />
        <img src={secondaryCTR} className="ctr" />
      <Container>
        <Row className="content">
          <Col css={pr79} lg={7} md={12} sm={12}>
            <h2 css={secTitle}>
              {sectionContent.title}
              <span>{sectionContent.highlight}</span>
            </h2>
            <p css={secDesk}>{sectionContent.text}</p>
          </Col>
          <Col lg={5} md={12} sm={12}>
            <div css={molecula}>
              <img src={sectionContent.img1} />
            </div>
          </Col>
        </Row>
        <Row>
        <Col className="pull-left" lg={4} md={4} sm={12}>
          <div css={aboutGroup}>
            <h3>Coordenação geral</h3>
            <p>Rafael Riemma</p>
          </div>

          <div css={aboutGroup}>
            <h3>Conselho de Direção</h3>
            <p>Daniel Lavouras</p>
            <p>Gustavo Wigman</p>
            <p>Felipe Pimentel</p>
          </div>

          <div css={aboutGroup}>
            <h3>Comissão Estratégica</h3>
            <p>Daniel Tenório Camêlo Soares</p>
            <p>Roberto Biselo</p>
            <p>Vinicius Lazzari</p>
          </div>
        </Col>

        <Col className="pull-left" lg={4} md={4} sm={12}>
          <div css={aboutGroup}>
            <h3>Comissão de Provas</h3>
            <p>Daniel Fonseca Lavouras</p>
            <p>Daniel Tenório Camêlo Soares</p>
            <p>Fernando M M Pimentel (Baga)</p>
            <p>Roberto Biselo</p>
            <p>Vinicius Lazzari</p>
            <p>Tito Matumato</p>
          </div>

          <div css={aboutGroup}>
            <h3>Revisão gramatical</h3>
            <p>Ana Paula Anghinoni Ramos</p>
            <p>Fernanda Feyh</p>
          </div>
        </Col>

        <Col className="pull-left" lg={4} md={4} sm={12}>
          <div css={aboutGroup}>
            <h3>Tecnologia</h3>
            <p>Danilo Formiga</p>
            <p>Diego Lins</p>
            <p>André Vildozo</p>
            <p>Bruna Fernandes</p>
            <p>Gabriel Maciel</p>
            <p>Jackson Luciano</p>
            <p>Janiele Dias</p>
          </div>
        </Col>
        </Row>
      </Container>
      <img src={secondaryCBR} className="cbr" />
    </section>
  );
};

export default AboutUs;
