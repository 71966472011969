/** @jsx jsx */
import { css } from "@emotion/react";

import { colors } from "./layout.styles"

export const mainSlider = css`

  margin-top: 97px; 

  .swiper-slide {
    height: 547px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-layer {
    background-color: ${colors.secondary};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    /* -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform 7000ms ease;
    transition: -webkit-transform 7000ms ease;
    transition: transform 7000ms ease;
    transition: transform 7000ms ease, -webkit-transform 7000ms ease; */
  }

  .swiper-pagination-bullet{
    background: ${colors.primaryVar1};
    opacity: 1;

    &.swiper-pagination-bullet-active{
      width: 20px;
      height: 8px;
      border-radius: 4px;
      background: ${colors.primaryVar2};
    }
  }
  


  .swiper-slide-active {
    /* .image-layer {
      -webkit-transform: scale(1.15);
      transform: scale(1.15);
    } */

    & h3,
    p,
    a {
      visibility: visible;
      opacity: 1;
      -webkit-transform: translateY(0) translateX(0);
      transform: translateY(0) translateX(0);
    }
  }

  & [class*="swiper-button-"]::after {
    display: none;
  }

  & [class*="swiper-button-"] {
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.35);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #000000;
    font-size: 24px;
    transition: all 500ms ease;
    display: none;
        &:hover {
        background-color: #fff;
        }
        @media (min-width: 768px) {
            width: 70px;
            height: 70px;
            display: flex;
        }
    }

  .container {
    position: relative;
  }
  & h3 {
    white-space: pre-line;
    font-size: 56px;
    color: #1e1e1e;
    line-height: 1.15em;
    font-weight: 700;
    margin: 0;
    margin-bottom: 30px;
    margin-top: 5px;

    /* @media (min-width: 768px) {
        font-size: 60px;
        margin-bottom: 40px;
    }
      @media (min-width: 992px) {
        margin-bottom: 40px;
        font-size: 70px;      
    }
    @media (min-width: 1200px) {
        font-size: 110px;
    } */
    span{
      font-family: 'Swanky and Moo Moo', cursive;
      color:${colors.primary};
      font-weight: 400;
      font-size: 76px;
    }
  }

  & p {
    color: #1e1e1e;
    margin: 0;
    margin-bottom:30px;
  }

  & a {

  }

  @media (min-width: 768px) {
        .swiper-button-next {
            right: 30px;
        }
        .swiper-button-prev {
            left: 30px;
        }
    }

    @media (min-width: 1200px) {
        .swiper-button-next {
            right: 60px;
        }
        .swiper-button-prev {
            left: 60px;
        }
    }

    @media (min-width: 320px) and (max-width: 767px) {
    .swiper-slide {
      height: auto;
      padding-bottom: 20px;
    }
    .row {
      flex-direction: column-reverse;
    }
    .swiper-pagination {
      top: 60%;
      bottom: inherit !important;
    }
    img{
      margin: 30px auto;
    }
    h3{
      font-size: 32px;
      margin: 20px auto;
      span{
        font-size: 46px;
      }
      img{
        display: none;
      }
    }
    p{
      font-size: 18px;
      text-align: center;
      line-height: 24px;
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    .swiper-slide {
      height: auto;
      padding: 80px 0;
    }
  }
`;

export const sliderBtn = css`
    font-size: 26px;
    padding: 10px 26px;
    border: 3px solid #383838;
    transition: .3s;
`

export const shapeImg = css`
    position: absolute;
    top: -25px;
    left: 4px;
`

export const infoSlider = css`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
`

export const imageSlider = css`
   img{
    max-width: 100%;
   }
`

